export default function Carousel() {
  return(
    <div className="container-fluid p-0">
      <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="w-100" src={require("../../images/carousel-1.jpg")} alt="Carousel 1"/>
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{maxWidth: "900px"}}>
                <h5 className="text-white text-uppercase mb-3 animated slideInDown">FUGACE</h5>
                <h1 className="display-1 text-white mb-md-4 animated zoomIn">Fugace, vous satisfaire au mieux</h1>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img className="w-100" src={require("../../images/carousel-2.jpg")} alt="Carousel 2"/>
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={{maxWidth: "900px"}}>
                <h5 className="text-white text-uppercase mb-3 animated slideInDown">FUGACE</h5>
                <h1 className="display-1 text-white mb-md-4 animated zoomIn">RÉACTIVITÉ - COMPÉTENCE - PROFESSIONNALISME</h1>
              </div>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Précédent</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Suivant</span>
        </button>
      </div>
    </div>
  )
}