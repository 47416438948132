import {useEffect} from 'react';
import About from "../components/general/about.component";
import Carousel from "../components/general/carousel.component";
import Service from "../components/general/service.component";

export default function Index() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return(
    <>
      <Carousel/>
      <About/>
      <Service/>
    </>
  )
}