
import {useEffect, useState} from 'react';
import { Link } from "react-router-dom";

export default function Header() {
  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener('scroll', stickyNavbar);

    return () => {
      window.removeEventListener('scroll', stickyNavbar);
    };
  }, []);

  const stickyNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 40 ? setStickyClass('sticky-top') : setStickyClass('');
    }
  };

  return(
    <>
      <div className="container-fluid bg-light ps-5 pe-0 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
          </div>
          <div className="col-md-6 text-center text-lg-end">
            <div className="position-relative d-inline-flex align-items-center bg-fugace text-white top-shape px-5">
              <div className="me-3 pe-3 border-end py-2">
                <p className="m-0"><i className="fa fa-envelope-open me-2"></i>contact@fugacecm.com</p>
              </div>
              <div className="py-2">
                <p className="m-0"><i className="fa fa-phone-alt me-2"></i>+237 699 826 114</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <nav className={`navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0 ${stickyClass}`}>
        <Link to="/home" className="navbar-brand p-0" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}>
          <h1 className="m-0 text-fugace"><img src={require("../../images/fugace.png")} className="logo" alt="logo"/> Fugace</h1>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <Link to="/home" className="nav-item nav-link" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}>Accueil</Link>
            <Link to="/about-us" className="nav-item nav-link" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}>À propos de nous</Link>
            <Link to="/contact-us" className="nav-item nav-link" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}>Contactez-nous</Link>
          </div>
        </div>
      </nav>
    </>
  )
}