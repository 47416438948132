import { Outlet } from "react-router-dom";
import Header from "../components/general/header.component";
import Footer from "../components/general/footer.component";


function Public() {
  return(
    <>
      <Header/>
      <Outlet/>   
      <Footer/>

      <button className="btn btn-lg btn-primary btn-lg-square rounded back-to-top" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}>
        <i className="bi bi-arrow-up"></i></button>
    </>
  );
}

export default Public;