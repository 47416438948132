import AboutLayout from "./layouts/about.layout";
import ContactLayout from "./layouts/contact.layout";
import Index from "./layouts/index.layout";


const routes = [
  {path:"/home", exact:true, name:"Accueil", element: Index},
  {path:"/about-us", exact:true, name:"À propos de nous", element: AboutLayout},
  {path:"/contact-us", exact:true, name:"Contactez-nous", element: ContactLayout},
];

export default routes;