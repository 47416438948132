export default function About() {
  return(
    <div className="container-fluid py-5 wow fadeInUp">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-7">
            <div className="section-title mb-4">
              <h5 className="position-relative d-inline-block text-fugace text-uppercase">À Propos</h5>
              <h1 className="display-5 mb-0 text-justify">Fugace, vous satisfaire au mieux, dans des délais les plus courts.</h1>
            </div>
            <h4 className="text-body fst-italic mb-4 text-justify">Depuis 2013, Fugace est une entreprise riche d’une longue histoire qui propose des prestations de qualité grâce à son écoute, son excellente compréhension des besoins de ses clients, l’engagement constant et le savoir-faire de ses équipes.</h4>
            <p className="mb-4 text-justify">Depuis plus de 10 ans, Fugace est spécialisée dans les Travaux de génie civil et bâtiments, d'Etude et réalisation des projets de froid et climatisation industrielle (fourniture, installation, réparation et maintenance des équipements en froid), de	Réalisation des travaux en électricité (électricité générale, installation d’équipements électriques et la réalisation d’infrastructures réseaux pour le tertiaire, l’industrie et l’habitat collectif), de Fournitures du matériel biomédical et de Fourniture du matériel informatique. Fugace a pour mission de devenir au Cameroun la référence incontournable sur toutes les problématiques autour de ses domaines de compéteences pour tout client et partenaire.
              Implantée à Limbé, Cité SIC et composée d’une vingtaine de professionnels dûment formés, notre entreprise accompagne ses clients dans la réalisation de leurs projets en génie civil et bâtiments, étude et réalisation des projets de froid et climatisation industrielle (fourniture, installation, réparation et maintenance des équipements en froid), en électricité (électricité générale, installation d’équipements électriques et la réalisation d’infrastructures réseaux pour le tertiaire, l’industrie et l’habitat collectif), de Fournitures du matériel biomédical et de Fourniture du matériel informatique tout en optimisant les coûts.
              </p>
            <div className="row g-3">
              <div className="col-sm-6 wow zoomIn">
                <h5 className="mb-3 text-justify"><i className="fa fa-check-circle text-fugace me-3"></i>Des solutions en adéquation avec vos envies.</h5>
                <h5 className="mb-3 text-justify"><i className="fa fa-check-circle text-fugace me-3"></i>Des solutions en adéquation avec votre budget.</h5>
              </div>
              <div className="col-sm-6 wow zoomIn">
                <h5 className="mb-3 text-justify"><i className="fa fa-check-circle text-fugace me-3"></i>Ouvert 24h/24 et 6j/7</h5>
                <h5 className="mb-3 text-justify"><i className="fa fa-check-circle text-fugace me-3"></i>Personnel professionnel</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-5" style={{minHeight: "500px"}}>
            <div className="position-relative h-100">
              <img className="position-absolute w-100 h-100 rounded wow zoomIn" src={require("../../images/about.jpg")} style={{objectFit: "cover"}} alt="about-pic"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}