import {useEffect} from 'react';
import About from "../components/general/about.component";
import Service from "../components/general/service.component";
import Pageheader from '../components/general/pageHeader.component';

export default function AboutLayout() {
  const page = {
    url: "/about-us",
    title: "À propos de nous",
  }

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return(
    <>
      <Pageheader page={page}/>
      <About/>
      <Service/>
    </>
  )
}