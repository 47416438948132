import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import routes from "./routes";
import Public from "./layouts/public.layout";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" name="Public" element={<Public/>} >
            {routes.map((route, index)=>{
                return (
                  route.element && (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={<route.element />}
                    />
                  )
                )
              })}
              <Route
                index
                element={<Navigate to="/home" replace />}
              /> 
              <Route
                path="*"
                exact={true}
                element={<Navigate to="/home" replace />}
              /> 
          </Route> 

        </Routes>
      </Router>
    </div>
  );
}

export default App;
