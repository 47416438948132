import { Link } from "react-router-dom";

export default function Pageheader(props) {
  return(
    <div class="container-fluid bg-primary py-5 hero-header mb-5">
      <div class="row py-3">
        <div class="col-12 text-center">
          <h1 class="display-3 text-white animated zoomIn">{props.page.title}</h1>
          <Link to="/home" class="h4 text-white">Accueil</Link>
          <i class="far fa-circle text-white px-2"></i>
          <Link to={props.page.url} class="h4 text-white">{props.page.title}</Link>
        </div>
      </div>
    </div>
  )
}