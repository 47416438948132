export default function Contact() {
  return(
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row g-5">
          <div className="col-md-6 wow slideInUp">
            <div className="bg-light rounded h-100 p-5">
              <div className="section-title">
                <h5 className="position-relative d-inline-block text-fugace text-uppercase">Contactez-nous</h5>
                <h1 className="display-6 mb-4">N'hésitez pas à nous contacter</h1>
              </div>
              <div className="d-flex align-items-center mb-2">
                <i className="bi bi-geo-alt fs-1 text-fugace me-3"></i>
                <div className="text-start">
                  <h5 className="mb-0">Notre bureau</h5>
                  <span>118 Limbé, Cité SIC, Cameroun</span>
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <i className="bi bi-envelope-open fs-1 text-fugace me-3"></i>
                <div className="text-start">
                  <h5 className="mb-0">Envoyez-nous un email</h5>
                  <span>contact@fugacecm.com</span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <i className="bi bi-phone-vibrate fs-1 text-fugace me-3"></i>
                <div className="text-start">
                  <h5 className="mb-0">Appelez-nous</h5>
                  <span>+237 699 826 114 | +237 679 199 028</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 wow slideInUp">
            <form>
              <div className="row g-3">
                <div className="col-12">
                  <input type="text" className="form-control border-0 bg-light px-4" placeholder="Votre Nom" style={{height: "55px"}}/>
                </div>
                <div className="col-12">
                  <input type="email" className="form-control border-0 bg-light px-4" placeholder="Votre Adresse Mail" style={{height: "55px"}}/>
                </div>
                <div className="col-12">
                  <input type="text" className="form-control border-0 bg-light px-4" placeholder="Sujet" style={{height: "55px"}}/>
                </div>
                <div className="col-12">
                  <textarea className="form-control border-0 bg-light px-4 py-3" rows="5" placeholder="Message"></textarea>
                </div>
                <div className="col-12">
                  <a className="btn btn-primary bg-fugace w-100 py-3" href="mailto:contact@fugacecm.com">Envoyer le message</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}