import { Link } from "react-router-dom";

export default function Footer() {
  return(
    <>
      <div className="container-fluid bg-dark text-light py-5">
        <div className="container pt-5">
          <div className="row g-5 pt-4">
            <div className="col-md-4">
              <h3 className="text-white mb-4">Liens Utiles</h3>
              <div className="d-flex flex-column justify-content-start">
                <Link className="text-light mb-2" to="/home" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}><i className="bi bi-arrow-right text-primary me-2"></i>Accueil</Link>
                <Link className="text-light mb-2" to="/about-us" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}><i className="bi bi-arrow-right text-primary me-2"></i>À propos de nous</Link>
                <Link className="text-light" to="/contact-us" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}><i className="bi bi-arrow-right text-primary me-2"></i>Contactez-nous</Link>
              </div>
            </div>
            <div className="col-md-4">
              <h3 className="text-white mb-4">Nos Services</h3>
              <div className="d-flex flex-column justify-content-start">
                <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Travaux de génie civil et bâtiments</Link>
                <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Etude et réalisation des projets de froid et climatisation industrielle </Link>
                <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Réalisation des travaux en électricité</Link>
                <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Fournitures du matériel biomédical</Link>
                <Link className="text-light" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Fourniture du matériel informatique</Link>
              </div>
            </div>
            <div className="col-md-4">
              <h3 className="text-white mb-4">Nos Contacts</h3>
              <p className="mb-2"><i className="bi bi-geo-alt text-primary me-2"></i>118 Limbé, Cité SIC, Cameroun</p>
              <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2"></i>contact@fugacecm.com</p>
              <p className="mb-0"><i className="bi bi-telephone text-primary me-2"></i>+237 699 826 114 | +237 679 199 028</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid text-light py-4" style={{background: "#051225"}}>
        <div className="container">
          <div className="row g-0">
            <div className="col-md-6 text-center text-md-start">
              <p className="mb-md-0">&copy; <Link to="#">Fugace</Link>, Tous droits réservés.</p>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <p className="mb-0">Conçu par <Link to="mailto:fredkameetome@gmail.com">Freddy KAME</Link></p>
            </div>
          </div>
        </div>
      </div>
    
    </>
  )
}