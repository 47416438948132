import {useEffect} from 'react';
import Contact from '../components/general/contact.component';
import Pageheader from '../components/general/pageHeader.component';

export default function ContactLayout() {
  const page = {
    url: "/contact-us",
    title: "Contactez-nous",
  }

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return(
    <>
      <Pageheader page={page}/>
      <Contact/>
    </>
  )
}