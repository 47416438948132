export default function Service() {
  return(
    <div className="container-fluid py-5 wow fadeInUp">
      <div className="container">
        <div className="row g-5 mb-5">
          <div className="col-lg-5 wow zoomIn" style={{minHeight: "400px"}}>
            <div className="position-relative h-100 rounded overflow-hidden">
              <img className="position-absolute w-100 h-100" src={require("../../images/before.jpg")} alt="desc" style={{objectFit: "cover"}}/>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="section-title mb-5">
              <h5 className="position-relative d-inline-block text-fugace text-uppercase">Nos services</h5>
              <h1 className="display-5 mb-0">Nous offrons les services divers</h1>
            </div>
            <div className="row g-5">
              <div className="col-md-6 service-item wow zoomIn">
                <div className="rounded-top overflow-hidden">
                  <img className="img-fluid" src={require("../../images/service-1.jpg")} alt=""/>
                </div>
                <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <h5 className="m-0">Génie civil</h5>
                </div>
              </div>
              <div className="col-md-6 service-item wow zoomIn">
                <div className="rounded-top overflow-hidden">
                  <img className="img-fluid" src={require("../../images/service-2.jpg")} alt=""/>
                </div>
                <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <h5 className="m-0">Génie Thermique et Climatique</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-5 wow fadeInUp">
          <div className="col-lg-7">
            <div className="row g-5">
              <div className="col-md-6 service-item wow zoomIn">
                <div className="rounded-top overflow-hidden">
                  <img className="img-fluid" src={require("../../images/service-3.jpg")} alt=""/>
                </div>
                <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <h5 className="m-0">Génie Électrique</h5>
                </div>
              </div>
              <div className="col-md-6 service-item wow zoomIn">
                <div className="rounded-top overflow-hidden">
                  <img className="img-fluid" src={require("../../images/service-4.jpg")} alt=""/>
                </div>
                <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <h5 className="m-0">Fournitures</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 service-item wow zoomIn">
            <div className="position-relative bg-fugace rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-4">
              <h3 className="text-white mb-3">Prenez un rendez-vous</h3>
              <p className="text-white mb-3">Nous sommes à votre entière disposition pour étudier votre projet et pour vous aider à atteindre vos objectifs.</p>
              <h2 className="text-white mb-0">+237 699 826 114</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}